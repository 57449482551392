$( document ).ready(function() {
    'use strict';
    /*$.cookieBar({
        fixed: true
    });*/
    jQuery.event.special.touchstart = {
        setup: function( _, ns, handle ){
            this.addEventListener("touchstart", handle, { passive: true });
        }
    };
    /* Animation on scroll */
    //window.wow.init();
});

searchInBox = function (inputIdElement, ulIdElement) {
    // Declare variables
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById(inputIdElement);
    filter = input.value.toUpperCase();
    ul = document.getElementById(ulIdElement);
    li = ul.getElementsByTagName('li');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
    }
}

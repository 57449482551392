require('./bootstrap');

//const WOW = require('./allaia/wow');
//window.wow = new WOW.WOW({ live: false });

//require('./allaia/jquery.nice-select');
//require('./allaia/jquery.magnific-popup');
//require('./allaia/jquery.countdown');
require('./allaia/jquery.mmenu.all');
//require('./allaia/owl.carousel');
//require('./allaia/carousel-home');
require('./allaia/ResizeSensor');
require('./allaia/isotope.min');
//require('./allaia/jquery.cookiebar');
require('./allaia/main');

window.LazyLoad = require('vanilla-lazyload');
window.LazyLoadInstance = new LazyLoad();

window.addEventListener(
    "LazyLoad::Initialized",
    function (event) {
        window.lazyLoadInstance = event.detail.instance;
    },
    false
);

require('./allaia/custom');
//require('./sb/sidebar');

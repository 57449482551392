(function ($) {

    "use strict";

    // Sticky nav
    var $headerStick = $('.Sticky');
    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 80) {
            $headerStick.addClass("sticky_element");
        } else {
            $headerStick.removeClass("sticky_element");
        }
        ;
    });

    // Menu Categories
    $(window).resize(function () {
        if ($(window).width() >= 768) {
            $('a[href="#"]').on('click', function (e) {
                e.preventDefault();
            });
            $('.categories').addClass('menu');
            $('.menu ul > li').on('mouseover', function (e) {
                $(this).find("ul:first").show();
                $(this).find('> span a').addClass('active');
            }).on('mouseout', function (e) {
                $(this).find("ul:first").hide();
                $(this).find('> span a').removeClass('active');
            });
            $('.menu ul li li').on('mouseover', function (e) {
                if ($(this).has('ul').length) {
                    $(this).parent().addClass('expanded');
                }
                $('.menu ul:first', this).parent().find('> span a').addClass('active');
                $('.menu ul:first', this).show();
            }).on('mouseout', function (e) {
                $(this).parent().removeClass('expanded');
                $('.menu ul:first', this).parent().find('> span a').removeClass('active');
                $('.menu ul:first', this).hide();
            });
        } else {
            $('.categories').removeClass('menu');
        }
    }).resize();

    // Mobile Mmenu
    var $menu = $("#menu").mmenu({
            "extensions": ["pagedim-black"],
            counters: false,
            keyboardNavigation: {
                enable: true,
                enhance: true
            },
            navbar: {
                title: ''
            },
            offCanvas: {
                pageSelector: "#page"
            },
            navbars: [{position: 'bottom', content: ['']}]
        },
        {
            // configuration
            clone: true,
            classNames: {
                fixedElements: {
                    fixed: "menu_fixed"
                }
            }
        });

    // Menu
    $('a.open_close').on("click", function () {
        $('.main-menu').toggleClass('show');
        $('.layer').toggleClass('layer-is-visible');
    });
    $('a.show-submenu').on("click", function () {
        $(this).next().toggleClass("show_normal");
    });
    $('a.show-submenu-mega').on("click", function () {
        $(this).next().toggleClass("show_mega");
    });
    $('a.btn_search_mob').on("click", function () {
        $('.search_mob_wp').slideToggle("fast");
    });

    // Opacity mask
    $('.opacity-mask').each(function () {
        $(this).css('background-color', $(this).attr('data-opacity-mask'));
    });

    /* Animation on scroll */
    //new WOW().init();

    // Scroll to top
    var pxShow = 800; // height on which the button will show
    var scrollSpeed = 500; // how slow / fast you want the button to scroll to top.
    $(window).scroll(function () {
        if ($(window).scrollTop() >= pxShow) {
            $("#toTop").addClass('visible');
        } else {
            $("#toTop").removeClass('visible');
        }
    });
    $('#toTop').on('click', function () {
        $('html, body').animate({scrollTop: 0}, scrollSpeed);
        return false;
    });

    // Tooltip
    $(window).resize(function () {
        if ($(window).width() <= 768) {
            $('.tooltip-1').tooltip('disable');
        } else {
            $('.tooltip-1').tooltip({html: true});
        }
    }).resize();


})(window.jQuery);
